import React from "react";
import Layout from '../components/Layout';
import { Link } from 'gatsby';

import Head from '../components/Head';

const NotFoundPage = () => {
  return (
    <Layout>
      <Head title="404" />
      <h1>NOPE!</h1>
      <h4>There is no page with such address <span role="img" aria-label="disoriented face">😕</span></h4>
      <Link to="/">Go back to the Start Page</Link>
    </Layout>
  );
};

export default NotFoundPage;
